import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarNotificationService } from 'src/app/services/snackbar-notification.service';
import { DeptLeads } from 'src/app/models/dept-leads';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { getDepartmentLeads } from '../../store/department-leads/department-leads.selector';
import { Store } from '@ngrx/store';
import { addDeptLeads } from '../../store/department-leads/department-leads.action';

@Component({
  selector: 'app-par-notification',
  templateUrl: './par-notification.component.html',
  styleUrls: ['./par-notification.component.scss'],
})
export class ParNotificationComponent implements OnInit {
  @Input() id: string;

  allNotifications: DeptLeads[] = [];
  currentLeadNotificaiton: DeptLeads[];
  noNotification: boolean;
  unallocatedNotification: boolean = false;
  chargingNotification: boolean = false;
  varianceNotification: boolean = false;
  notificationList: any[] = [];
  currentManager: string;
  currentManagerName: string = '';
  managerInfo: any;
  notificationLoading: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<ParNotificationComponent>,
    private snack: SnackbarNotificationService,
    private repoService: ApiService,
    private storage: StorageService,
    private store: Store ) {
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications() {
    forkJoin([
      this.repoService.getItems('get-dept-leads', {}),
    ]).subscribe((res) => {
      this.allNotifications = res[0] as DeptLeads[];
      this.store.dispatch(addDeptLeads({departmentLeads : res[0] as DeptLeads[]}));
      this.displayNotifications();
    });
  }

  displayNotifications() {
    this.currentManager = this.storage.getCurrentManager();
    // Loop Dept. Leads array
    this.currentLeadNotificaiton = this.allNotifications.filter(
      (x) => x.manager_source_worker_id == this.currentManager
    );
    for (let y of this.currentLeadNotificaiton) {
      this.currentManagerName = y.manager_full_name;

      // for incorrect charging notification
      if (y.incorrect_charging_notification == 'True') {
        this.chargingNotification = true;
        this.noNotification = false;
      } else {
        this.chargingNotification = false;
      }

      // for unnlocated notification
      if (y.unallocated_notification == 'True') {
        this.unallocatedNotification = true;
        this.noNotification = false;
      } else {
        this.unallocatedNotification = false;
      }

      // for variance notification
      if (y.variance_notification == 'True') {
        this.varianceNotification = true;
        this.noNotification = false;
      } else {
        this.varianceNotification = false;
      }

      // to check if everything is False
      if (
        y.incorrect_charging_notification == 'False' &&
        y.unallocated_notification == 'False' &&
        y.variance_notification == 'False'
      ) {
        this.noNotification = true;
        this.unallocatedNotification = false;
        this.chargingNotification = false;
        this.varianceNotification = false;
        this.currentManagerName = y.manager_full_name;
      }
    }
    this.notificationLoading = false;
  }
  // ----- Clear Unallocated -----
  clearUnallocated() {
    this.notificationLoading = true;
    let clearUnallocated = {
      source_worker_id: this.currentManager,
      clear_command: 'clear_unallocated_notification',
    };
    this.repoService
      .saveItems('update_department_notifications', clearUnallocated)
      .subscribe(
        (res) => {
          this.unallocatedNotification = false;
          this.getNotifications();
        },
        (error: any) => {
          console.log(error);
          this.unallocatedNotification = true;
          this.getNotifications();
        }
      );
  }
  // ----- Clear Charging -----
  clearCharging() {
    this.notificationLoading = true;
    let clearCharging = {
      source_worker_id: this.currentManager,
      clear_command: 'clear_incorrect_charging_notification',
    };
    this.repoService
      .saveItems('update_department_notifications', clearCharging)
      .subscribe(
        (res) => {
          this.chargingNotification = false;
          this.getNotifications();
        },
        (error: any) => {
          console.log(error);
          this.chargingNotification = true;
          this.getNotifications();
        }
      );
  }
  // ----- Clear Variance -----
  clearVariance() {
    this.notificationLoading = true;
    let clearVariance = {
      source_worker_id: this.currentManager,
      clear_command: 'clear_variance_notification',
    };
    this.repoService
      .saveItems('update_department_notifications', clearVariance)
      .subscribe(
        (res) => {
          this.varianceNotification = false;
          this.getNotifications();
        },
        (error: any) => {
          console.log(error);
          this.varianceNotification = true;
          this.getNotifications();
        }
      );
  }

  closeDialog() {
    this.dialogRef.close('closed');
  }
}
